import React, { useState, useEffect } from "react";

import Tutorial from "./Tutorial"; // Reuse your Technique component

function Collections() {
  const [collections, setCollections] = useState([]);
  const [techniques, setTechniques] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch collections and techniques
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch collections.json
        const collectionsResponse = await fetch("/collections.json");
        const collectionsData = await collectionsResponse.json();

        // Fetch kyohon.json
        const kyohonResponse = await fetch("/kyohon.json");
        const kyohonData = await kyohonResponse.json();

        setCollections(collectionsData);
        setTechniques(kyohonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter techniques belonging to a specific collection
  const getTechniquesForCollection = (collectionId) => {
    return techniques.filter((technique) =>
      technique.collections?.includes(collectionId)
    );
  };

  if (loading) {
    return <div>Loading collections...</div>;
  }

  return (
    <div className="collections-container">
      <h1>Collections</h1>
      {collections.length > 0 ? (
        collections.map((collection) => {
          const relatedTechniques = getTechniquesForCollection(collection.id);

          return (
            <div key={collection.id} className="collection-item">
              <h2 className="collection-title">{collection.name}</h2>
              <p className="collection-description">
                {collection.description || "No description available"}
              </p>

              {/* List of Techniques */}
              {relatedTechniques.length > 0 ? (
                <div className="technique-list">
                  {relatedTechniques.map((technique) => (
                    <Tutorial
                      key={technique.url}
                      technique={technique}
                      isExpanded={false} // Optional: No need for expansion here
                      onExpand={() => {}} // Placeholder function
                    />
                  ))}
                </div>
              ) : (
                <p>No techniques available for this collection.</p>
              )}
            </div>
          );
        })
      ) : (
        <p>No collections found.</p>
      )}
    </div>
  );
}

export default Collections;
