import React from "react";
import Setup from "./components/Setup";
import Step from "./components/Step";
import End from "./components/End";
import Legend from "./components/Legend";
import Builder from "./components/Builder";
import "./css/notation.css";
export default function Notation() {
  return (
    <div className="page-content">
      <div className="header">
        <h1 className="header-title">Notation</h1>
      </div>
      <div className="intro">
        <p>
          <a
            href="https://www.anna-noctuelle.com/shibari-sequence-notation"
            rel="noreferrer"
            target="_blank"
          >
            {" "}
            Anna Noctuelle
          </a>
          , a Shibari model and performer with a ballet background, developed a
          graphical notation system for documenting and creating Shibari
          sequences.
        </p>
        <p>
          Inspired by dance and musical notation, her system enables
          practitioners to record, recall, and share sequences easily, making it
          invaluable for workshops, performances, and creative exploration. This
          universal language for Shibari fosters learning and creativity while
          preserving the art form. My guide is a personal tool inspired by
          Anna’s work.
        </p>
        <p>
          For deeper insights, I highly recommend attending her classes or
          exploring her materials directly.
        </p>
      </div>
      <div>
        <h2>Describing the setup</h2>
        <p>
          The setup is the starting point of the sequence. It describes the
          initial position of the model and the preparatory steps before the
          first tie. Max and min height
        </p>
        <div className="examples">
          <Setup maxheight="6" minheight="-2" pointType="bamboo" />
          <Setup maxheight="6" minheight="-2" pointType="ring" />
          <Setup maxheight="6" minheight="-2" pointType="hashira" />
        </div>
        <h2>Notating a tie</h2>
        <p>
          A tie is a sequence of steps that create a knot or a connection
          between two points. Each step is a movement or a change in the tie.
          The notation system describes the steps to create a tie.
        </p>
        <div className="examples">
          <Step tie="TK" tieHeight="3" />
          <Step tie="calf_binder" tieHeight="-2" />
        </div>
        <h2>Adding an upline</h2>
        <p></p>
        <div className="examples">
          <Step
            tie="TK"
            uplineDirection="up"
            uplineHeight="3"
            uplineId="1"
            attachmentPosition="6"
            attachmentType="y_hanger"
          />
          <Step
            tie="calf_binder"
            uplineDirection="up"
            uplineHeight="2"
            uplineId="2"
          />
        </div>
      </div>
      {/* <img
        className="examplesimg"
        src="notation-examples.jpg"
        alt="Notation examples"
      /> */}

      <Builder />
      <Legend />
      <div className="footer"></div>
      <div className="performance">
        <Setup maxheight="6" minheight="-2" pointType="bamboo" />
        <Step tieHeight="3" tie="TK" />
        <Step
          lineHeight="3"
          tie="TK"
          attachmentType="y_hanger"
          uplineDirection="up"
          uplineHeight="3"
          uplineId="1"
          attachmentPosition="6"
        />
        <Step tie="thigh_cuff" tieSide="R" />
        <Step
          tie="thigh_cuff"
          uplineDirection="up"
          uplineHeight="2"
          uplineId="1"
        />
        <Step tie="futo_right" />
        <Step
          tie="futo_right"
          uplineDirection="up"
          attachmentPosition="9"
          uplineId="1"
          uplineHeight="6"
        />
        <Step
          tie="thigh_cuff"
          uplineId="2"
          uplineHeight="6"
          uplineDirection="up"
        />
        <div className="compound-step">
          <Step tie="thigh_cuff" uplineDirection="up" />
          <Step tie="crunch" accentValue="1" />
          <Step tie="futo_right" uplineDirection="up" />
        </div>
        <End />
      </div>
    </div>
  );
}
