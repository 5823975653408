import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import your components for each page
import Kyohon from "./Kyohon";
import Collections from "./Collections";
import Notation from "./Notation";
import Notate from "./Notate";
import "./App.css";

const App = () => {
  return (
    <Router basename="/">
      <div>
        <main>
          <Routes>
            <Route path="/" element={<Kyohon />} />
            <Route path="/notation" element={<Notation />} />
            <Route path="/notate" element={<Notate />} />
            <Route path="/collections" element={<Collections />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
