import React from "react";

export default function Setup({ maxheight, minheight, pointType }) {
  return (
    <div className="setup">
      <div className="min-max-height">
        <div className="max-height">{maxheight}</div>
        <div className="min-height">{minheight}</div>
      </div>
      <img className="setup-img" src={`svg/${pointType}.svg`} alt="" />
    </div>
  );
}
