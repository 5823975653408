import React from "react";

function Tutorial({ technique }) {
  const { name, author, platform, description, url, tags, image } = technique;

  return (
    <div className="technique-item">
      <h2 className="technique-title">{name}</h2>
      <h3 className="technique-author">{author !== "Unknown" && author}</h3>
      {image && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            src={`./meta_images/${image}`}
            alt={name}
            className="preview-image"
          />
        </a>
      )}
      <div className="technique-content">
        <p className="technique-description">
          {description || "No description available"}
        </p>

        {tags && tags.length > 0 && (
          <p className="technique-tags">{tags.join(" - ")}</p>
        )}
        <a
          className="learn-more-btn"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on {platform}
        </a>
      </div>
    </div>
  );
}

export default Tutorial;
