import React from "react";

export default function Step({
  tieHeight,
  tie,
  uplineId,
  uplineHeight,
  uplineDirection,
  attachmentType,
  tieSide,
  attachmentPosition,
  accentValue,
  optional,
}) {
  return (
    <div className="step">
      {uplineId && uplineHeight && uplineDirection && (
        <div className="upline-info">
          <div className="upline-id">{uplineId}</div>
          <div className="upline-height">{uplineHeight}</div>
        </div>
      )}
      {uplineDirection && (
        <img className="arrow arrow-up" src="svg/arrow.svg" alt="TK" />
      )}
      {tieHeight && !uplineDirection && (
        <div className="tie-height">{tieHeight}</div>
      )}
      {accentValue && <div className="tie-height">{accentValue}</div>}
      {tie && <img className="tie" src={`svg/${tie}.svg`} alt="TK" />}
      {tieSide && <div className={`tie-side ${tieSide}`}>{tieSide}</div>}
      {(attachmentType || attachmentPosition) && (
        <div className="attachment">
          {attachmentType && (
            <img
              className="attachment-type"
              src={`svg/${attachmentType}.svg`}
              alt="SCT"
            />
          )}
          {attachmentPosition && (
            <div className="attachment-position">{attachmentPosition}</div>
          )}
        </div>
      )}
      {optional && (
        <div className="optional">
          <img className="attachment-type" src={`svg/optional.svg`} alt="SCT" />
        </div>
      )}
    </div>
  );
}
