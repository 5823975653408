import React from "react";
import notation from "../notation.json"; // Adjust the path to your JSON file

export default function Legend() {
  return (
    <>
      <h2>Legend</h2>
      <div className="legend">
        {[
          "setup",
          "attachments",
          "upper body ties",
          "hip ties",
          "leg ties",
          "dynamics",

          "bamboo",
        ].map((type) => (
          <section key={type}>
            <h2>{type}</h2>

            <div className="grid">
              {notation
                .filter((item) => item.type === type)
                .map((item) => (
                  <figure key={item.name}>
                    <img
                      src={`svg/${item.id}.svg`}
                      alt={item.name}
                      className={`${type}-icon`}
                    />
                    <figcaption>
                      <strong>{item.name}</strong>
                      <p>{item.description}</p>
                    </figcaption>
                  </figure>
                ))}
            </div>
          </section>
        ))}
      </div>
    </>
  );
}
