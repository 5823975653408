import React, { useState, useEffect, useRef } from "react";
import Setup from "./components/Setup";
import Step from "./components/Step";
import End from "./components/End";

export default function NotationBuilder() {
  const [notation, setNotation] = useState([
    {
      type: "setup",
      maxheight: 6,
      minheight: -2,
      pointType: "bamboo",
      notes: "",
    },
  ]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [symbols, setSymbols] = useState([]);
  const [totalUplines, setTotalUplines] = useState(0);

  const performanceRef = useRef(null);
  useEffect(() => {
    fetch("./notation.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setSymbols(data))
      .catch((error) => {
        console.error("Failed to fetch symbols:", error);
        setSymbols([]); // Fallback to empty array
      });
  }, []);

  const addStep = () => {
    const newStep = {
      type: "tie",
      tie: "",
      height: "",
      uplineId: 1,
      uplineDirection: null,
      attachmentType: "",
      attachmentPosition: "",
      optional: false,
      notes: "",
    };
    const updatedNotation = [...notation];
    updatedNotation.splice(currentStepIndex + 1, 0, newStep);
    setNotation(updatedNotation);
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const addPause = () => {
    const updatedNotation = [...notation];
    updatedNotation.splice(currentStepIndex + 1, 0, { type: "pause" });
    setNotation(updatedNotation);
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const addEnd = () => {
    const updatedNotation = [...notation];
    updatedNotation.splice(currentStepIndex + 1, 0, { type: "end" });
    setNotation(updatedNotation);
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const deleteCurrentStep = () => {
    if (notation.length > 1) {
      const updatedNotation = notation.filter(
        (_, index) => index !== currentStepIndex
      );
      setNotation(updatedNotation);
      setCurrentStepIndex((prev) => (prev > 0 ? prev - 1 : 0));
    }
  };

  const updateCurrentStep = (key, value) => {
    if (currentStepIndex !== null) {
      if (key === "uplineId" && value > totalUplines) {
        setTotalUplines(value);
      }
      const updatedNotation = [...notation];
      updatedNotation[currentStepIndex] = {
        ...updatedNotation[currentStepIndex],
        [key]: value,
      };
      setNotation(updatedNotation);
    }
  };

  const toggleOptional = () => {
    if (currentStepIndex !== null) {
      const updatedNotation = [...notation];
      const currentStep = updatedNotation[currentStepIndex];
      updatedNotation[currentStepIndex] = {
        ...currentStep,
        optional: !currentStep.optional,
      };
      setNotation(updatedNotation);
    }
  };

  const navigateSteps = (direction) => {
    if (currentStepIndex !== null) {
      const newIndex = currentStepIndex + direction;
      if (newIndex >= 0 && newIndex < notation.length) {
        setCurrentStepIndex(newIndex);
      }
    }
  };

  const exportToJson = () => {
    const blob = new Blob([JSON.stringify(notation, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "notation.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(notation, null, 2)).then(
      () => alert("JSON copied to clipboard!"),
      (err) => console.error("Failed to copy JSON: ", err)
    );
  };

  const saveAsImage = () => {
    if (performanceRef.current) {
      const element = performanceRef.current;
      const originalBackground = element.style.backgroundColor;
      const originalStep = currentStepIndex;
      setCurrentStepIndex(null);
      element.style.backgroundColor = "white";
      import("html-to-image").then((htmlToImage) => {
        htmlToImage.toPng(element).then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "performance.png";
          link.href = dataUrl;
          link.click();
          element.style.backgroundColor = originalBackground;
          setCurrentStepIndex(originalStep);
        });
      });
    }
  };

  const handleJsonChange = (e) => {
    try {
      const updatedNotation = JSON.parse(e.target.value);
      setNotation(updatedNotation);
    } catch (error) {
      console.error("Invalid JSON input:", error);
    }
  };

  const selectStep = (index) => {
    currentStepIndex !== index
      ? setCurrentStepIndex(index)
      : setCurrentStepIndex(null);
  };

  return (
    <div>
      <h1>Notate</h1>
      <div>
        <div className="performance" id="performance" ref={performanceRef}>
          <div>{totalUplines > 0 && totalUplines}</div>
          {notation.map((item, index) => {
            const isActive = index === currentStepIndex;
            switch (item.type) {
              case "setup":
                return (
                  <div
                    key={index}
                    className={`step-container ${
                      isActive ? "current-step" : ""
                    }`}
                    onClick={() => selectStep(index)}
                  >
                    <Setup
                      maxheight={item.maxheight}
                      minheight={item.minheight}
                      pointType={item.pointType}
                    />
                  </div>
                );
              case "tie":
              case "upline":
                return (
                  <div
                    key={index}
                    className={`step-container ${
                      isActive ? "current-step" : ""
                    }`}
                    onClick={() => selectStep(index)}
                  >
                    <Step
                      tieHeight={item.height}
                      uplineHeight={item.height}
                      tie={item.tie}
                      uplineId={item.uplineId}
                      uplineDirection={item.uplineDirection}
                      attachmentType={item.attachmentType}
                      attachmentPosition={item.attachmentPosition}
                      optional={item.optional}
                    />
                  </div>
                );
              case "pause":
                return (
                  <div
                    key={index}
                    className={`step-container pause ${
                      isActive ? "current-step" : ""
                    }`}
                    onClick={() => selectStep(index)}
                  >
                    |
                  </div>
                );
              case "end":
                return (
                  <div
                    key={index}
                    className={`step-container ${
                      isActive ? "current-step" : ""
                    }`}
                    onClick={() => selectStep(index)}
                  >
                    <End />
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
        <div className="navigation-buttons">
          <button onClick={() => navigateSteps(-1)}>Previous</button>
          <button onClick={() => navigateSteps(1)}>Next</button>
        </div>
        <div className="controls">
          {currentStepIndex === 0 ? (
            <div className="setup-controls">
              <h3>Edit Setup</h3>
              <select
                onChange={(e) => updateCurrentStep("pointType", e.target.value)}
                value={notation[currentStepIndex]?.pointType || ""}
              >
                <option value="bamboo">Bamboo</option>
                <option value="hashira">Hashira</option>
                <option value="ring">Ring</option>
              </select>
              <input
                type="number"
                placeholder="Max Height"
                value={notation[currentStepIndex]?.maxheight || ""}
                onChange={(e) => updateCurrentStep("maxheight", e.target.value)}
              />
              <input
                type="number"
                placeholder="Min Height"
                value={notation[currentStepIndex]?.minheight || ""}
                onChange={(e) => updateCurrentStep("minheight", e.target.value)}
              />
            </div>
          ) : (
            <div>
              <div className="current-step-controls">
                <h3>Edit Current Step</h3>
              </div>
              <>
                <h2>Tie</h2>
                <label>Tie</label>
                <select
                  onChange={(e) => updateCurrentStep("tie", e.target.value)}
                  value={notation[currentStepIndex]?.tie || ""}
                >
                  <option value="">Select Tie</option>
                  {Object.entries(
                    symbols.reduce((groups, symbol) => {
                      if (
                        symbol.type !== "setup" &&
                        symbol.type !== "attachments" &&
                        symbol.type !== "dynamics"
                      ) {
                        if (!groups[symbol.type]) {
                          groups[symbol.type] = [];
                        }
                        groups[symbol.type].push(symbol);
                      }
                      return groups;
                    }, {})
                  ).map(([type, group]) => (
                    <optgroup key={type} label={type.toUpperCase()}>
                      {group.map((symbol) => (
                        <option key={symbol.id} value={symbol.id}>
                          {symbol.name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </select>

                <label>Tie height</label>
                <input
                  type="number"
                  placeholder="Height"
                  value={notation[currentStepIndex]?.height || ""}
                  onChange={(e) => updateCurrentStep("height", e.target.value)}
                />
                <h2>Upline</h2>
                <label>Upline ID</label>
                <input
                  type="number"
                  max={totalUplines + 1}
                  placeholder="Upline ID"
                  value={notation[currentStepIndex]?.uplineId || 1}
                  onChange={(e) =>
                    updateCurrentStep("uplineId", e.target.value)
                  }
                />
                <label>Upline direction</label>
                <select
                  onChange={(e) =>
                    updateCurrentStep("uplineDirection", e.target.value)
                  }
                  value={notation[currentStepIndex]?.uplineDirection || ""}
                >
                  <option value="">None</option>
                  <option value="up">Up</option>
                  <option value="down">Down</option>
                </select>
                <label>Upline attachment</label>
                <select
                  onChange={(e) =>
                    updateCurrentStep("attachmentType", e.target.value)
                  }
                  value={notation[currentStepIndex]?.attachmentType || ""}
                >
                  <option value="">Select Attachment Type</option>
                  {symbols
                    .filter((symbol) => symbol.type === "attachments")
                    .map((symbol) => (
                      <option key={symbol.id} value={symbol.id}>
                        {symbol.name}
                      </option>
                    ))}
                </select>
                <label>Upline attachment position</label>
                <select
                  onChange={(e) =>
                    updateCurrentStep("attachmentPosition", e.target.value)
                  }
                  value={notation[currentStepIndex]?.attachmentPosition || ""}
                >
                  <option value="">None</option>
                  <option value="12">12 - model front</option>
                  <option value="6">6 - model back</option>
                  <option value="3">3 - model right</option>
                  <option value="9">9 - model left</option>
                </select>
              </>
            </div>
          )}
          <button onClick={addStep}>Add Step</button>
          <button onClick={addPause}>Add Pause</button>
          <button onClick={addEnd}>Add End</button>
          <button onClick={deleteCurrentStep}>Delete Current Step</button>
          <button onClick={toggleOptional}>Toggle Optional</button>
          <button onClick={exportToJson}>Export to JSON</button>
          <button onClick={copyToClipboard}>Copy JSON</button>
          <button onClick={saveAsImage}>Save as Image</button>
        </div>
        <textarea
          value={JSON.stringify(notation, null, 2)}
          onChange={handleJsonChange}
          rows="10"
          style={{ width: "100%", marginTop: "20px" }}
        />
      </div>
    </div>
  );
}
